*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none;
}
html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  max-width: 100%;
  font-family: sans-serif;
  line-height: 1.5;
}
body{
  overflow-x: hidden;
}


