@tailwind base;
@tailwind components;
@tailwind utilities;

#logo-title {
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    font-size: 2rem;
    letter-spacing: .1rem;
    position: relative;
    opacity: 0;
    animation-duration: 1s;
    animation-delay: 0.5s;
    animation-fill-mode: forwards;
    cursor: pointer;
    margin-bottom: auto;
}

#logo-title.animate {
    animation-name: reveal;
}


@keyframes reveal {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}



@keyframes slide-in {
    from {
        opacity: 0;
        transform: translateX(-50%);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
}
