.home-navbar {
    padding: 1rem;
    position: fixed;
    background-color: transparent; /* Start transparent */
    backdrop-filter: none; /* No blur initially */
    width: 100%;
    z-index: 1000;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    font-style: normal;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: background-color 0.3s ease, backdrop-filter 0.3s ease;
}

.home-navbar.scrolled {
    background-color: rgba(0, 0, 0, 0.3); /* Black with 30% opacity */
    backdrop-filter: blur(10px); /* Blur effect */
}


/* Burger Menu Styles */
.burger-menu {
    display: none;
    flex-direction: column;
    justify-content: space-between;
    width: 25px;
    height: 20px;
    cursor: pointer;
    z-index: 1001; /* Above nav-buttons */
}

.burger-bar {
    width: 100%;
    height: 3px;
    background-color: white;
    transition: all 0.4s ease;
}

.burger-menu.open .burger-bar:nth-child(1) {
    transform: rotate(-45deg) translate(-5px, 7px);
}

.burger-menu.open .burger-bar:nth-child(2) {
    opacity: 0;
}

.burger-menu.open .burger-bar:nth-child(3) {
    transform: rotate(45deg) translate(-5px, -7px);
}

/* Navigation Buttons */
.nav-buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    opacity: 0;
    transform: translateX(-50%);
    animation-duration: 1s;
    animation-delay: 0.8s;
    animation-fill-mode: forwards;
    font-weight: 300;
    margin-left: auto;
    margin-right: auto;
}

.nav-buttons.animate-nav-buttons {
    animation-name: slide-in;
}

.nav-button {
    font-size: 0.8rem;
    padding: 1rem;
    background-color: transparent;
    border: none;
    color: white;
    cursor: pointer;
    font-weight: 300;
    position: relative;
    overflow: hidden;
    display: inline-block;
    min-width: 190px;
    max-width: 190px;
}

.nav-button:hover {
    font-weight: 800;
}


.language-dropdown select:focus {
    outline: none;
}

.arrow {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 8px;
    vertical-align: middle;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 6px solid white;
    transition: transform 0.3s ease;
}

.arrow.open {
    transform: rotate(180deg);
}

.language-dropdown {
    position: relative;
    cursor: pointer;
    color: white;
    display: flex;
    justify-content: center;
    font-weight: 300;
}

.dropdown-header-home-navbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    background-color: transparent;
    border-radius: 4px;
    cursor: pointer;
    font-weight: 300;
    font-size: 0.85rem;
    position: relative;
}

.flag-icon {
    width: 24px;
    height: 24px;
    margin-right: 10px;
}

.dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    width: 100%;
    z-index: 10;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease, visibility 0.3s ease;
}

@media(max-width: 500px) {
    .dropdown-menu{
        background-color: rgba(0, 0, 0, 0.9);
    }
}

.language-dropdown.open .dropdown-menu {
    opacity: 1;
    visibility: visible;
}

.language-dropdown-item {
    padding: 10px;
    cursor: pointer;
    color: white;
    font-size: 14px;
    border-radius: 2px;
    font-weight: 300;
    text-align: center;
}

.language-dropdown-item:hover {
    background-color: gray;
}

.language-dropdown-item img {
    width: 24px;
    height: 24px;
    margin-right: 10px;
}

.language-select:focus {
    outline: none;
}

/* Updated Arrow Positioning in Mobile View */
@media (max-width: 1024px) {
    .nav-buttons {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        background-color: rgba(0, 0, 0, 0.85);
        padding: 1rem;
        gap: 0.5rem;
        transform: translateX(-100%);
        transition: transform 0.3s ease-in-out;
        opacity: 0;
    }

    .nav-buttons.mobile-open {
        transform: translateX(0);
        opacity: 1;
    }

    .nav-buttons.mobile-closed {
        transform: translateX(-100%);
        opacity: 0;
    }

    .burger-menu {
        display: flex;
    }

    .nav-buttons.animate-nav-buttons {
        animation: none;
    }

    .language-dropdown{
        width: auto; /* Override to auto to prevent full-width stretching */
        margin-right: auto;
        margin-left: auto;
    }

    .dropdown-header-home-navbar {
        width: auto; /* Adjust width to fit content */
    }
}

